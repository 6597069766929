import React from "react";
import CardPage from "../../layout/CardPage";
import { useGetProveedorresActivos } from "./useGetProveedorresActivos";
import { ProveedorItem } from "./_ProveedorItem";
import { Row } from "antd";

export const ProveedoresActivos = () => {
  const { proveedores } = useGetProveedorresActivos();
  return <CardPage titulo="Proveedores Activos">
  <Row>
  {proveedores.map((p, i)=> (<ProveedorItem proveedor={p} key={i} />))}
  </Row>  
  </CardPage>;
};
