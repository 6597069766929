import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Popconfirm } from "antd";
import React, { useState } from "react";
import { TituloModal } from "../../../components/tituloModal/TituloModal";
import { useParams } from "react-router-dom";
import { startDesasignaProveedor } from "../../../context/actions/detalleActions";
import { useDispatch } from "react-redux";

export const Desasignar = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [motivo, setMotivo] = useState();
  const { asistenciaID } = useParams();

  const submit = () => {
    const body = { motivo, asistenciaID };
    setIsOpen(false);
    dispatch(startDesasignaProveedor(body));
  };

  return (
    <div>
      <div
        onClick={() => setIsOpen(true)}
        className="marcarStep"
        style={{ color: "red" }}
      >
        <ExclamationCircleOutlined /> Desaignar !
      </div>

      <Modal
        title={<TituloModal titulo="Quitar Proveedor de Servicio" />}
        footer={[
          <Button key={1} onClick={() => setIsOpen(false)} size="small" danger>
            cerrar
          </Button>,
        ]}
        open={isOpen}
        onCancel={() => setIsOpen(false)}
      >
        <div className="inputLabel">
          Motivo por el cual se va a quitar el proveedor
        </div>
        <Input.TextArea onChange={({ target }) => setMotivo(target.value)} />
        <Popconfirm title="¿Estas Seguro en Desasignar?" onConfirm={submit}>
          <Button disabled={!motivo} danger style={{ width: "100%", marginTop: 5 }} type="primary">
            Desaignar
          </Button>
        </Popconfirm>
      </Modal>
    </div>
  );
};
