import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { startGetProveedoresActivos } from "../../context/actions/proveedoresActivosActions";

export const useGetProveedorresActivos = () => {
  const dispatch = useDispatch();
  const { proveedores } = useSelector(
    (state) => state.proveedoresActivosReducer
  );

  useEffect(() => {
    dispatch(startGetProveedoresActivos());

    setInterval(() => {
      dispatch(startGetProveedoresActivos());
    }, 60000);
  }, [dispatch]);

  return { proveedores };
};
