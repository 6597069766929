import React, { useEffect } from "react";
import CardPage from "../../layout/CardPage";
import { useDispatch, useSelector } from "react-redux";
import { startGetPropuestas } from "../../context/actions/serviciosPropuestos";
import { Items } from "./items/Items";
import { Row } from "antd";
import { SinPropuestas } from "./items/_SinPropuestas";
export const Carretero = () => {
  const dispatch = useDispatch();
  const { asistenciasIDs } = useSelector((state) => state.propuestasReducer);
  useEffect(() => {
    dispatch(startGetPropuestas());

    const interval = setInterval(() => {
      dispatch(startGetPropuestas());
    }, 1000 * 60 * 0.5); // 30 segundos

    return () => clearInterval(interval);
  }, [dispatch]);

  return (
    <CardPage titulo="Propuesta de Proveedores: Servicio Carretero">
      <div style={{ minHeight: "85vh" }}>
        {asistenciasIDs.length === 0 ? (
          <SinPropuestas />
        ) : (
          <Row gutter={[10, 10]}>
            {asistenciasIDs.map((asistenciaID, index) => (
              <Items asistenciaID={asistenciaID} key={index} />
            ))}
          </Row>
        )}
      </div>
    </CardPage>
  );
};
