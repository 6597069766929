import { Col, Row } from "antd";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import BotonSubmit from "../../../components/Boton/BotonSubmit";
import { MapaGoogle } from "../../../components/mapaGoogle/MapaGoogle";
import CardPage from "../../../layout/CardPage";
import { Cita } from "./cita/Cita";
import { Conexion } from "./conexion/Conexion";
import { createHDIService } from "../../../context/actions/hdiActions";
import { startPostDestino } from "../../../context/actions/detalleActions";

//
export const MapaDestino = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { asistenciaID } = useParams();
  const [latLng, setLatLng] = useState({
    lat: 19.364612026633605,
    lng: -99.18838994738087,
  });

  const [body, setBody] = useState({ esCita: false });

  const getDatos = (datos) => {
    const salida = {
      asistenciaID,
      direccion: datos.direccionCompleta,
      municipio: datos.municipio,
      estado: datos.estado,
      cp: datos.cp,
      lat: datos.lat.toString(),
      lng: datos.lng.toString(),
    };
    setBody({
      ...body,
      ...salida,
    });
  };

  const handleClick = () => {
    dispatch(startPostDestino(body)).then((resp) => {
      if (resp.code === 0) void dispatch(createHDIService(body));
      history.push(`/detalleAsistencia/${asistenciaID}`);
    });
  };

  const AgregaDatos = (ele) =>
    setBody({
      ...body,
      ...ele,
    });

  //
  return (
    <CardPage
      titulo="Establecer Dirección Destino"
      tituloBoton="Regresar"
      accionBoton={() => history.push(`/detalleAsistencia/${asistenciaID}`)}
    >
      <MapaGoogle getDatos={getDatos} latLng={latLng} setLatLng={setLatLng} />

      <Row gutter={10}>
        <Col span={3}>
          <Conexion AgregaDatos={AgregaDatos} />
        </Col>
        <Col span={6}>
          <Cita AgregaDatos={AgregaDatos} esCita={body.esCita} />
        </Col>
        <Col span={4}>
          <BotonSubmit
            disabled={!body}
            onClick={handleClick}
            tituloBoton="Guardar Destino"
          />
        </Col>
      </Row>
    </CardPage>
  );
};
