import { Button } from "antd";
import React, { useState } from "react";
import { ContenidoModal } from "./_ContenidoModal";
import { DollarOutlined } from "@ant-design/icons";

export const ModalPropuesta = ({ desglose }) => {
  const [open, setOpen] = useState(false);

  if (!desglose) return <></>;
  if (desglose.length === 0) return <></>;
  return (
    <>
      <DollarOutlined
        className="eyeIcon"
        onClick={() => setOpen(true)}
        style={{ fontSize: 18, color: "orange" }}
      />
      <ContenidoModal setOpen={setOpen} open={open} desglose={desglose} />
    </>
  );
};
