import { Table } from "antd";
import React from "react";
import { useHistory } from "react-router-dom";
import { Controles } from "./Controles";
import { useColumns } from "./useColumns";
import { useFiltroAsistencias } from "./useFiltroAsistencias";

export const Listado = () => {
  const history = useHistory();
  const { columns } = useColumns();
  const { listaFinal, filter } = useFiltroAsistencias();
  return (
    <>
      <Controles filter={filter} />
      <Table
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) =>
              history.push(`/detalleAsistencia/${record.asistenciaID}`),
          };
        }}
        rowClassName="renglonTabla"
        size="small"
        rowKey="asistenciaID"
        dataSource={listaFinal}
        columns={columns}
      />
    </>
  );
};
