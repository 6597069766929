import { Button, Col, Row, Select } from "antd";
import { useFormIncidencia } from "./useFormIncidencia";
import { motivoIncidenciaArriboOpciones } from "../../../../constants/cons";

export const FormaIncidencia = ({ servicio }) => {
  const {
    disabled,
    submitIncidencia,
    motivoIncidencia,
    setMotivoIncidencia,
  } = useFormIncidencia(servicio);

  return (
    <Row gutter={10}>
      <Col span={16}>
        <Select
          size="small"
          value={motivoIncidencia}
          onChange={setMotivoIncidencia}
          style={{ width: "100%" }}
          placeholder="Incidencia"
          options={motivoIncidenciaArriboOpciones}
        />
      </Col>
      <Col span={8}>
        <Button
          size="small"
          disabled={disabled}
          onClick={submitIncidencia}
          type="primary"
          danger
        >
          Agregar Incidencia
        </Button>
      </Col>
    </Row>
  );
};
