import { Button, Form, message, Modal, Select } from "antd";
import { useEffect, useState } from "react";
import axios from "axios";
import { URL_BASE } from "../../../../constants/url";
import { useParams } from "react-router-dom";
import { post } from "../../../../utils/fetch";
import { useDispatch } from "react-redux";
import { startLoadAsistencia } from "../../../../context/actions/detalleActions";

export const AsignacionApi = () => {
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const [data, setData] = useState([]);
  const { asistenciaID } = useParams();

  const getProveedoresApi = () => {
    axios
      .get(`${URL_BASE}/api/ProveedorAPI/${asistenciaID}`)
      .then((res) => setData(res.data));
  };

  useEffect(() => {
    getProveedoresApi();
  }, []);

  useEffect(() => {
    return () => {
      setData([]);
    };
  }, []);

  const onfinish = async (values) => {
    const body = {
      asistenciaID,
      proveedorID: values.proveedorID,
    };

    const result = await post(`${URL_BASE}/api/proveedorApi`, body);
    if (result.code === 0) {
      message.success(result.data || "Proveedor asignado");
      setOpen(false);
      void dispatch(startLoadAsistencia(asistenciaID, false));
    } else {
      message.error(result.error || "Ha ocurrido un error");
    }
  };

  const options = data.map((item) => ({
    label: `(${item.conteo})  ${item.nombreComercial}`,
    value: item.proveedorID,
  }));

  

  return (
    <div style={{ width: "100%" }}>
      {data.length > 0 && (
        <Button
          onClick={() => setOpen(true)}
          type={"text"}
          className={"botonCardLeft2"}
          danger
        >
          Asignación directa
        </Button>
      )}
      <Modal open={open} footer={null} onCancel={() => setOpen(false)}>
        <Form layout={"vertical"} onFinish={onfinish}>
          <Form.Item name="proveedorID" label={"Proveedor"}>
            <Select options={options} />
          </Form.Item>
          <Button htmlType={"submit"} type={"primary"}>
            Asignar
          </Button>
        </Form>
      </Modal>
    </div>
  );
};
