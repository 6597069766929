export const types = {
  setLoadingStatus: "[loadingAction] setLoadingStatus",
  setCalculing: "[loadingAction] setCalculing",
  setLoginErr: "[authErrAcion] setLoginErr",
  storeUserData: "[loginAcion] storeUserData",
  setLogged: "[loginAcion] setLogged",
  setElAguilaAuth: "[asistenciasActions] setElAguilaAuth",

  setRegisterDeviceErr: "[authErrAcion] setRegisterDeviceErr",
  setBrowserAllowed: "[loginAcion] setBrowserAllowed",
  storeCitasAbiertas: "[asistencias actions] storeCitasAbiertas",

  //// notificaciones
  notification: "[notificacionAction] notification",
  storeVersionBackend: "[notificacionAction] storeVersionBackend",

  ///
  storeClientes: "[clientesActions] storeClientes",

  //////// errores
  setErrBuscaValidador: "[erroresActions] setErrBuscaValidador",
  cleanErroresBuscaValidador: "[erroresActions] cleanErroresBuscaValidador",

  //

  // asistencias

  storeClientesCampanias: "[capturaActions] storeClientesCampanias",
  setCaptura: "[capturaActions] setCaptura",
  setCapturaStep: "[capturaActions] setCapturaStep",
  setCapturaCampos: "[capturaActions] setCapturaCampos",
  storeServicios: "[capturaActions] storeServicios",
  storeValidadorInfo: "[capturaActions] storeValidadorInfo",
  cleanCaptura: "[capturaActions] cleanCaptura",

  setNewLatLng: "[capturaActions] setNewLatLng",
  setEsperandoSMS: "[capturaActions] setEsperandoSMS",
  storeAsistenciaID: "[capturaActions] storeAsistenciaID",

  // Mapa
  setGeoDataOrigen: "[capturaActions] setGeoDataOrigen",

  /// detalle
  setFolderPagina: "[detalleActions] setFolderPagina",
  setAsistenciaDetalle: "[detalleActions] setAsistenciaDetalle",
  storeAsistencia: "[detalleActions] storeAsistencia",
  setLoadingDetalle: "[detalleActions] setLoadingDetalle",
  storeSucursalesByServicio: "[detalleActions] storeSucursalesByServicio",
  setProveedorGeos: "[detalleActions] setProveedorGeos",
  clearProveedorGeos: "[detalleActions] clearProveedorGeos",

  storeProveedorCostos: "[detalleActions] storeProveedorCostos",
  storeHistorialAsignacion: "[detalleActions] storeHistorialAsignacion",
  storeAsistenciaHistorial: "[detalleActions] storeAsistenciaHistorial",
  clearProveedorCostos: "[detalleActions] clearProveedorCostos",
  storeAsistenciaFiles: "[detalleActions] storeAsistenciaFiles",

  setLoadingHistorialAsignacion: "[detalleActions] setLoadingHistorialAsignacion",
  // servicios
  storeAsistenciasByValidador1:
    "[asistenciasActions] storeAsistenciasByValidador1",
  storeServiciosByMes: "[asistenciasActions] storeServiciosByMes",

  storeMisServicios: "[asistenciasActions] storeMisServicios",

  setFiltroAsistencia: "[asistenciasActions] setFiltroAsistencia",

  /// GNP API
  storeSiniestro: "[gnpApiActions] storeSiniestro",
  storeSiniestros: "[gnpApiActions] storeSiniestros",
  storeEstatusList: "[gnpApiActions] storeEstatusList",
  setUltimaVerificacion: "[gnpApiActions] setUltimaVerificacion",
  storeCatServiciosGNP: "[gnpApiActions] storeCatServiciosGNP",

  //// Semaforo
  storeAsistenciasSemaforo: "[semaforoActions] storeAsistenciasSemaforo",
  storeAsistenciasCitas: "[semaforoActions] storeAsistenciasCitas",

  //// Polizario
  // El aguila
  storeElAguilaLista: "[polizarioActions] storeElAguilaLista",
  storeElAguilaPoliza: "[polizarioActions] storeElAguilaPoliza",
  setClienteID: "[polizarioActions] setClienteID",
  setLoadingPolizario: "[polizarioActions] setLoadingPolizario",
  setVistaResultados: "[polizarioActions] setVistaResultados",

  // qualitas
  setQualitasResponse: "[polizarioActions] setQualitasResponse",

  //Chat
  setConnection: "[chat] setConnection",
  setCarreteros: "[carreteroActions] setCarreros",
  setConnectionProveedores: "[proveedoresActivos] setProveedoresActivos",

  // Desglose de costos
  setCostos: "[costosActions] setCostos",
  toggleModalLibrary: "[libraryPdf] openModalLibrary",
  saveHdiDetailsService: "[hdiActions] saveHdiDetailsService",
  saveHDIFieldsToCapture: "[hdiActions] saveFieldsToCapture",

  // Library

  saveConditions: "[conditionsActions] getConditions",
  setPDFInScreen: "[libraryPDF] setPDFInScreen",
  setCurrentPDFId: "[libraryPDF] setCurrentPDFId",
  openModalMain: "[libraryPDF] openModalMain",
  setCurrentUrlPDF: "[libraryPDF] setCurrentUrlPDF",
  saveMinimezedFiles: "[libraryPDF] saveMinimezedFiles",
  removeMinimizedFile: "[libraryPDF] removeMinimizedFile",
  saveCostos: "[deslglose] saveCostos",
  changeValidationVersion: "[appReduer] changeValidVersion",
  showModalValidVersion: "[appReduer] showModal",
  saveDistance: " [detalleReducer] saveDistance ",

  // Seguimiento
  storeActividades: " [detalleReducer] storeActividades ",
  storeIncidencias: " [detalleReducer] storeIncidencias ",

  // propuestas de proveedor para carretero
  storePropuestas: " [detalleReducer] storePropuestas ",

  // proveedores Activos
  storeProveedoresActivos: " [proveedoresActivosReducer] storeProveedoresActivos ",

  
};
