export const composeErrorNotification = (message, description) => {
  return {
    type: "error",
    message,
    description,
    dateTime: Date.now(),
    duration: 10,
  };
};

export const composeInfoNotification = (message) => {
  return {
    type: "info",
    message,
    dateTime: Date.now(),
    duration: 2,
  };
};

export const composeSuccessNotification = (message) => {
  return {
    type: "success",
    message,
    dateTime: Date.now(),
    duration: 5,
  };
};
