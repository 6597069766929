import React from "react";
import { dateFromString } from "../../../../utils/dateFromString";
import { Col, Row, Spin } from "antd";
import { useGetActividades } from "./useGetActividades";


export const ListaActividades = ({ servicio }) => {
  const { listado: actividades, loading } = useGetActividades(servicio.asistenciaID);

if(loading) return <Spin />; 

if(actividades.length === 0) return <p>Sin actividades</p>;
  //
  return (
    <div style={{ maxHeight: 400, overflowY: "auto" }}>
      {actividades.map((a, i) => (
        <Row gutter={5} key={i} className="rowActividades">
          <Col span={11} style={{ color: "orange" }}>
            {dateFromString(a.fecha).f1} - {a.usuarioNombre} [{a.tipoActividadSeguimiento}]
          </Col>
    
          <Col span={13}>{a.comentarios}</Col>
        </Row>
      ))}
    </div>
  );
};
