import { Col, Row } from "antd";
import React from "react";
import { dateFromString } from "../../../../utils/dateFromString";
import { LocalCarreteroIcon } from "../../../../components/localCarretero/LocalCarreteroIcon";
import { Controles } from "./_Controles";
import { Listados } from "./_Listados";

export const ServicioItem = ({ servicio }) => {
  const [open, setOpen] = React.useState(false);

  //
  return (
    <div className={`TableRow ${open && "TableRowSelected"}`}>
      <Row onClick={() => setOpen(!open)}>
        <Col xs={8} xxl={2}>
          {servicio.asistenciaID}
        </Col>
        <Col xs={8} xxl={7}>
          {servicio.proveedor}
        </Col>
        <Col xs={8} xxl={3}>
          {servicio.servicio}
        </Col>
        <Col xs={8} xxl={2}>
          {dateFromString(servicio.fechaAsignacion).dateStep}
        </Col>
        <Col xs={8} xxl={2}>
          {dateFromString(servicio.fechaTiempoLlegada).dateStep}
        </Col>

        <Col xs={8} xxl={7}>
          <Row>
            <Col span={3}>
              <LocalCarreteroIcon localCarretero={servicio.localCarretero} />
            </Col>
            <Col> {servicio.ubicacion}</Col>
          </Row>
        </Col>
      </Row>
      {open && (
        <>
          <Controles servicio={servicio} />
          <Listados servicio={servicio} />
        </>
      )}
    </div>
  );
};
