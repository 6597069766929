import { Col, Row } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { dateFromString } from "../../../../utils/dateFromString";

export const ListaIncidencias = () => {
  
  const { incidencias } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  return (
    <div style={{height: 500, overflowY: 'auto'}}>
      {incidencias.map((i, index) => (
        <div className="TableRowSimple">
          <Row gutter={10} key={index}>
            <Col span={10} style={{ color: "orange" }}>
              {dateFromString(i.fechaIncidencia).f1}
            </Col>
            <Col span={6} style={{ color: "orange" }}>
              {i.usuarioIncidenciaNombre}{" "}
            </Col>
            <Col span={6}>
              {i.momentoIncidencia === 1 ? "[Asignacion]" : "[Arribo]"}
            </Col>
            <Col span={24}>{i.motivoIncidencia}</Col>
          </Row>
        </div>
      ))}
    </div>
  );
};
