import { Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ItemsPropuesta } from "./ItemsPropuesta";
import { ArrowRightOutlined, SendOutlined } from "@ant-design/icons";

export const Items = ({ asistenciaID }) => {
  const [rows, setRows] = useState([]);
  const { propuestas } = useSelector((state) => state.propuestasReducer);

  useEffect(() => {
    const props = propuestas.filter(
      (propuesta) => propuesta.asistenciaID === asistenciaID
    );
    setRows(props);
  }, [asistenciaID]);


  const abreServicio = () => window.open(`detalleAsistencia/${asistenciaID}`, '_blank');
  //
  return (
    <Col xs={24} xxl={12}>
      <div className="cajaPropuesta">
        <Row>
          <Col span={6}>
            <div onClick={abreServicio} className="cajaPropuesta_Titulo">{asistenciaID}</div>
          </Col>
          <Col span={18} style={{textAlign: 'center', fontWeight: 600, marginTop: 10}}>
            {rows[0]?.origen} <ArrowRightOutlined style={{ color: "gray" }} />{" "}
            {rows[0]?.destino}
          </Col>
        </Row>

        <div className="cajaPropuesta_Propuestas">
          {rows.map((row, index) => (
            <ItemsPropuesta key={index} data={row} />
          ))}
        </div>
      </div>
    </Col>
  );
};
