import React, { useEffect } from "react";
import {  URL_ASISTENCIA_INCIDENCIA } from "../../../../constants/url";
import { get } from "../../../../utils/fetch";

export const useGetIncidencias = (asistenciaID) => {
  const [listado, setListado] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const startGetIncidenciasByAsistenciaID= async () => {
    setLoading(true);
    const url =    `${URL_ASISTENCIA_INCIDENCIA}/GetIncidenciasByAsistenciaID/${asistenciaID}`
    const result = await get(url);

    setListado(result.data);
    setLoading(false);
  };

  useEffect(() => {
    startGetIncidenciasByAsistenciaID();
  }, []);

  return { loading, listado };
};
