import { Suspense, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter as Router, Switch } from "react-router-dom";
import { consLogged } from "../constants/consLogged";

import { verificaLogin } from "../context/actions/loginActions";
import { LayoutPage } from "../layout/LayoutPage";

import Login from "../views/auth/Login";
import { CitasAbiertas } from "../views/citasAbiertas/CitasAbiertas";
import { Captura } from "../views/crearServicio/captura/Captura";
import { CrearServicio } from "../views/crearServicio/CrearServicio";
import { DetalleServicio } from "../views/detalleServicio/DetalleServicio";
import { MapaDestino } from "../views/detalleServicio/mapaDestino/MapaDestino";

import { Home } from "../views/home/Home";
import { MisServicios } from "../views/misServicios/MisServicios";
import { Catch } from "../views/recursos/gnpApi/catch/Catch";
import { GnpApi } from "../views/recursos/gnpApi/GnpApi";
import { Recursos } from "../views/recursos/Recursos";
import { Semaforo } from "../views/semaforo/Semaforo";
import { TodosServicios } from "../views/todosServicios/TodosServicios";

//
import PrivateRoute from "./PrivateRoute";
import PublicRoute from "./PublicRoute";
import ListadoChats from "../views/listadoChats/ListadoChats";
import { Carretero } from "../views/carretero/Carretero";

import { Spin } from "antd";
import { Library } from "../views/library/Library";
import { HdiApi } from "../views/hdiApi/HDIApi";
import { DetallesServicioHdi } from "../views/hdiApi/detallesHdiApi/DetallesServicioHdi";
import { SemaforoHome } from "../views/semaforoHome/SemaforoHome";
import { SemaforoSeguimiento } from "../views/semaforoHome/semaforoSeguimiento/SemaforoSeguimiento";
import { ProveedoresActivos } from "../views/proveedoesActivos/ProveedoresActivos";

export default function Routes() {
  const dispatch = useDispatch();
  const { logged } = useSelector((state) => state.userReducer);

  useEffect(() => {
    dispatch(verificaLogin());
  }, [dispatch]);

  if (logged === consLogged.INICIANDO) return <div>cargando...</div>;

  return (
    <Router>
      <div
        style={{
          height: "100vh",
          width: 15,
          backgroundColor: "#ca2228",
          position: "fixed",
          zIndex: 999,
        }}
      ></div>

      <Switch>
        <PublicRoute
          isAuthenticated={logged}
          exact
          path="/login"
          component={Login}
        />
        <LayoutPage>
     
          <Suspense
            fallback={
              <div style={{ marginTop: 30 }}>
                {" "}
                <Spin size="large" />
              </div>
            }
          >
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/"
              component={Home}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/crearServicio"
              component={CrearServicio}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/semaforo"
              component={Semaforo}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/semaforoHome"
              component={SemaforoHome}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/semaforoSeguimiento"
              component={SemaforoSeguimiento}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/library"
              component={Library}
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/GnpApi"
              component={GnpApi}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/GnpApi/catch/:folioAsignacion"
              component={Catch}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/captura/:campaniaID"
              component={Captura}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/mapaDestino/:asistenciaID"
              component={MapaDestino}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/detalleAsistencia/:asistenciaID"
              component={DetalleServicio}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/misServicios"
              component={MisServicios}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/todosServicios"
              component={TodosServicios}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/citasAbiertas"
              component={CitasAbiertas}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/recursos"
              component={Recursos}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/carretero"
              component={Carretero}
            />
            <PrivateRoute
              isAuthenticated={logged}
              component={ProveedoresActivos}
              exact
              path="/provedoresActivos"
            />
            <PrivateRoute
              isAuthenticated={logged}
              component={HdiApi}
              exact
              path="/hdiApi"
            />
            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/detallesHdiApi/:id"
              component={DetallesServicioHdi}
            />

            <PrivateRoute
              isAuthenticated={logged}
              exact
              path="/listadoChats"
              component={ListadoChats}
            />
          </Suspense>
        </LayoutPage>
      </Switch>
    </Router>
  );
}
