import React, { useEffect } from "react";
import { URL_ACTIVIDADES_SEGUIMIENTO } from "../../../../constants/url";
import { get } from "../../../../utils/fetch";

export const useGetActividades = (asistenciaID) => {
  const [listado, setListado] = React.useState([]);
  const [loading, setLoading] = React.useState(false);

  const startGetActividadesByAsistenciaID = async () => {
    setLoading(true);
    const url = `${URL_ACTIVIDADES_SEGUIMIENTO}/GetActividadesByAsistenciaID/${asistenciaID}`;
    const result = await get(url);

    setListado(result.data);
    setLoading(false);
  };

  useEffect(() => {
    startGetActividadesByAsistenciaID();
  }, []);

  return { loading, listado };
};
