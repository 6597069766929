import { Col } from "antd";
import React from "react";
import { dateFromString } from "../../utils/dateFromString";

export const ProveedorItem = ({ proveedor }) => {
  return (
    <Col xs={24} md={12} lg={10}  xxl={6}>
      <div className="ProveedorActivo_Caja">
        <div className="ProveedorActivo_Nobre">{proveedor.nombreComercial}</div>
        <div className="ProveedorActivo_UltimoAcceso">
          {dateFromString(proveedor.ultimaActividadPortal).f1}
        </div>
        <div
          className="ProveedorAtivo_Circulo"
          style={{
            backgroundColor: proveedor.estatus === 1 ? "green" : "silver",
          }}
        >

        </div>
      </div>
    </Col>
  );
};
