import img from "../../../assets/travolta.png";

export const SinPropuestas = () => {
  return (
    <div style={{paddingTop: 20, paddingLeft: 20}}>
      <img src={img} style={{ marginLeft: 10, height: 70, width: 70 }} alt="travolta" />
      <div>Sin Registros</div>
    </div>
  );
};
