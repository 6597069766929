import React from "react";
import { useDispatch } from "react-redux";
import { startPostActividad } from "../../../../context/actions/seguimientoActions";

export const useFormActividad = (servicio) => {
  const dispatch = useDispatch();
  const [body, setBody] = React.useState({
    asistenciaID: servicio.asistenciaID,
  });

  const handleChange = ({ target: { name, value } }) => {
    setBody({
      ...body,
      [name]: value,
    });
  };



  const handleChangeSelect = (name, value) => {
    setBody({
      ...body,
      [name]: value,
    });
  };

  const handleClick = () => {
    dispatch(startPostActividad(body)); // 2= arribo
    setBody({ asistenciaID: servicio.asistenciaID });
  };

  const disabled = !body.tipoActividadSeguimientoID || !body.comentarios;

  return { disabled, body, handleChange, handleChangeSelect, handleClick };
};
