import React from "react";
import { useDispatch } from "react-redux";
import { startSetIncidencia2 } from "../../../../context/actions/incidenciasActions";

export const useFormIncidencia = (servicio) => {
  const dispatch = useDispatch();
  const [motivoIncidencia, setMotivoIncidencia] = React.useState();

  const submitIncidencia = () => {
    let post = {
      asistenciaID: servicio.asistenciaID,
      motivoIncidencia,
      momentoIncidencia: 2, // 2= arribo
    };
    dispatch(startSetIncidencia2(post));
    setMotivoIncidencia({});
  };

  const disabled = !motivoIncidencia;
  return {
    disabled,
    submitIncidencia,
    motivoIncidencia,
    setMotivoIncidencia,
  };
};
