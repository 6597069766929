import { Col, Row, Spin } from "antd";
import React from "react";
import { dateFromString } from "../../../../utils/dateFromString";
import { useGetIncidencias } from "./useGetIncidencias";

export const ListaIncidencias = ({servicio}) => {
  
const { listado: incidencias, loading } = useGetIncidencias(servicio.asistenciaID)

if(loading) return <Spin />

if(incidencias.length === 0) return <p>Sin incidencias</p>

  return (
    <div style={{maxHeight: 400, overflowY: 'auto'}}>
      {incidencias.map((i, index) => (
      
          <Row gutter={10} key={index} className="rowActividades">
            <Col span={10} style={{ color: "orange" }}>
              {dateFromString(i.fechaIncidencia).f1} -   {i.usuarioIncidenciaNombre}{" "}  {i.momentoIncidencia === 1 ? "[Asignacion]" : "[Arribo]"}
            </Col>
        
            <Col span={14}>{i.motivoIncidencia}</Col>
          </Row>
     
      ))}
    </div>
  );
};
