import { types } from "../types/types";

const init = {
 proveedores:[]
};
export const proveedoresActivosReducer = (state = init, action) => {
  switch (action.type) {
    case types.storeProveedoresActivos:
      return {
        ...state,
        proveedores: action.payload,
      };

    default:
      return state;
  }
};
