import { Button, Col, Input, Row, Select } from "antd";
import { useFormActividad } from "./useFormActividad";
import { ActividadesSeguimientoOptions } from "../../../../constants/cons";

//
export const FormaActividad = ({ servicio }) => {
  const { disabled, body, handleChange, handleChangeSelect, handleClick } =
  useFormActividad(servicio);

  //
  return (
    <Row gutter={10}>
      <Col span={6}>
        <Select
        size="small"
          value={body.tipoActividadSeguimientoID}
          name="tipoActividadSeguimientoID"
          onChange={(value) =>
            handleChangeSelect("tipoActividadSeguimientoID", value)
          }
          style={{ width: "100%" }}
          placeholder="Tipo de actividad"
          options={ActividadesSeguimientoOptions}
        />
      </Col>
      <Col span={12}>
        <Input
          size="small"
          onChange={handleChange}
          name="comentarios"
          value={body.comentarios}
          placeholder="Comentarios"
        />
      </Col>
      <Col span={5}>
        <Button   size="small" disabled={disabled} onClick={handleClick} type="primary">
          Agregar Actividad
        </Button>
      </Col>
    </Row>
  );
};
