import { useState } from "react";
import { useParams } from "react-router-dom";
import { Modal, DatePicker } from "antd";

import { useDispatch, useSelector } from "react-redux";
import {
  ClockCircleOutlined,
  PauseCircleOutlined,
} from "@ant-design/icons";
import {
  startSetStep,
  startUpdateFecha,
} from "../../../context/actions/detalleActions";
import { dateFromString } from "../../../utils/dateFromString";

import {
  hdiTipoFechas,
  putNotification,
} from "../../../context/actions/hdiActions";
import { Desasignar } from "./_Desasignar";


export const Arribo = ({
  asistencia: { estatusID, fechaArribo, esGnpApi, folioGnp },
}) => {
  const dispatch = useDispatch();
  const { asistenciaID } = useParams();
  const [showModal, setShowModal] = useState(false);
  const [fecha, setFecha] = useState("");
  const { estatusFinalID } = useSelector(
    (state) => state.detalleReducer.asistencia
  );

  const handleClick = () => {
    dispatch(startSetStep());
    dispatch(putNotification(hdiTipoFechas.fechaArribo));
  };

  const handleModalOK = () => {
    const body = {
      fecha,
      asistenciaID,
      tipo: 1, // arribo
    };
    dispatch(startUpdateFecha(body));
    setShowModal(false);
  };

  const onDateChange = (momentTime, stringTime) => setFecha(stringTime);

  ////////////////////////////
  if (estatusID === 3 && estatusFinalID === 1)
    return (
      <>
        <div onClick={handleClick} className="marcarStep">
          <PauseCircleOutlined /> Marcar arribo
        </div>
        <hr />
        <Desasignar />
      </>
    );

  ////////////////////////////
  const date = dateFromString(fechaArribo);

  if (estatusID > 3)
    return (
      <div onDoubleClick={() => setShowModal(true)}>
        <ClockCircleOutlined /> {date.dateStep}
        <Modal
          title="Cambiar Fecha Arribo"
          open={showModal}
          onOk={handleModalOK}
          onCancel={() => setShowModal(false)}
        >
          <DatePicker showTime onChange={onDateChange} />
        </Modal>
      </div>
    );

  ////////////////////////////
  return <div></div>;
};
