import {
    URL_ACTIVIDADES_SEGUIMIENTO,
  } from "../../constants/url";
  import { composeErrorNotification, composeSuccessNotification } from "../../utils/composeNotification";
  import { get, post } from "../../utils/fetch";
  import { types } from "../types/types";
  import { notification } from "./notificationActions";

  

  export const startPostActividad = (body) => {
    return async (dispatch)=>{ 
        const url = `${URL_ACTIVIDADES_SEGUIMIENTO}/PostActividad`;

        const result = await post(url, body);
        if (result.code === 0) {
          dispatch(startGetActividadesByAsistenciaID(body.asistenciaID))
            dispatch(
                notification(
                    composeSuccessNotification(
                    `${result.data}`
                  )
                )
              );
        } else {
          dispatch(
            notification(
              composeErrorNotification(
                `${result.error}`
              )
            )
          );
        }

        }
  }



  //

  export const startGetActividadesByAsistenciaID = (asistenciaID) => {
    return async (dispatch) => {
      const url = `${URL_ACTIVIDADES_SEGUIMIENTO}/GetActividadesByAsistenciaID/${asistenciaID}`;
      const result = await get(url);
      if (result.code === 0) {
        dispatch(storeActividades(result.data));
      } else {
        dispatch(
          notification(
            composeErrorNotification(
              `${result.error}`
            )
          )
        );
      }
    };
  }

  const storeActividades = (actividades) => ({
    type: types.storeActividades,
    payload: actividades,
  });