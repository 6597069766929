import { URL_SERVICIOS_PROPUESTOS } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";
import { types } from "../types/types";
import { notification } from "./notificationActions";

export const startGetPropuestas = () => {
  return async (dispatch) => {
    const resp = await get(`${URL_SERVICIOS_PROPUESTOS}/GetPropuestas`);
    if (resp.code === 0) {
      dispatch(storePropuestas(resp.data));
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Error de conexion - Get Propuestas",
            `${resp.error}`
          )
        )
      );
    }
  };
};

const storePropuestas = (propuestas) => ({
  type: types.storePropuestas,
  payload: propuestas,
});

/////7

export const startPostAceptarPropuesta = (body) => {
  return async (dispatch) => {
    const resp = await post(
      `${URL_SERVICIOS_PROPUESTOS}/PostAceptarPropuesta`,
      body
    );
    if (resp.code === 0) {
      dispatch(notification(composeSuccessNotification(resp.data)));
      dispatch(startGetPropuestas());
    } else {
      dispatch(
        notification(
          composeErrorNotification(
            "Aceptar Propuesta",
            `${resp.error}`
          )
        )
      );
    }
  };
};
