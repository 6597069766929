import { Col, Row } from "antd";
import React from "react";
import { FormaActividad } from "./_FormaActividad";
import { FormaIncidencia } from "./_FormaIncidencia";

export const Controles = ({ servicio }) => {
  return (
    <Row gutter={[10,10]} style={{marginTop: 20}}>
      <Col xs={24} xxl={12}>
        <FormaActividad servicio={servicio} />
      </Col>
      <Col xs={24} xxl={12}>
        <FormaIncidencia servicio={servicio} />
      </Col>
    </Row>
  );
};
