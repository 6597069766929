import { Col, Row } from "antd";
import { ListaActividades } from "./_ListaActividades";
import { ListaIncidencias } from "./_ListaIncidencias";

export const Listados = ({ servicio }) => {
  return (
    <Row gutter={10} style={{ marginTop: 20 }}>
      <Col span={12}>
        <ListaActividades servicio={servicio} />
      </Col>
      <Col span={12}>
        <ListaIncidencias servicio={servicio} />
      </Col>
    </Row>
  );
};
