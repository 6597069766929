import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  cleanCaptura,
  startLoadClientesCampanias,
} from "../../context/actions/capturaActions";
import CardPage from "../../layout/CardPage";
import { ClientesCampanias } from "./clientesCampanias/ClientesCampanias";

export const CrearServicio = () => {
  const dispatch = useDispatch();


  useEffect(() => {
  //  dispatch(startLoadClientesCampanias());
   // dispatch(cleanCaptura());
  }, [dispatch]);



  return (
    <CardPage titulo="Selecciona la Campaña">
      <ClientesCampanias />
    </CardPage>
  );
};
