import {
    URL_PROVEEDORES_ACTIVOS2
  } from "../../constants/url";
  import {
    composeErrorNotification,
  } from "../../utils/composeNotification";
  import { get, post } from "../../utils/fetch";
  
  import { types } from "../types/types";
  import { notification } from "./notificationActions";
  


  export const startGetProveedoresActivos = () => {
    return async (dispatch) => {
      try {
        const response = await get(`${URL_PROVEEDORES_ACTIVOS2}/GetProveedoresActivos`);
        if (response.code === 0) {
      
          dispatch(storeProveedoresActivos(response.data));
        } else {
          dispatch(
            notification(
              composeErrorNotification(
                "Error al obtener los proveedores activos",
                response.error
              )
            )
          );
        }
      } catch (error) {
        dispatch(
          notification(
            composeErrorNotification("Error al obtener los proveedores activos", error)
          )
        );
      }
    };
  }

  const storeProveedoresActivos = (proveedores) => ({
    type: types.storeProveedoresActivos,
    payload: proveedores,
  });