import { URL_ASISTENCIA_INCIDENCIA } from "../../constants/url";
import {
  composeErrorNotification,
  composeSuccessNotification,
} from "../../utils/composeNotification";
import { get, post } from "../../utils/fetch";
import { types } from "../types/types";
import { startLoadAsistencia } from "./detalleActions";
import { notification } from "./notificationActions";

export const startSetIncidencia = (body) => {
  return async (dispatch) => {
    const result = await post(
      `${URL_ASISTENCIA_INCIDENCIA}/SetIncidencia`,
      body
    );
    if (result.code === 0) {
      dispatch(startLoadAsistencia(body.asistenciaID, false))
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(notification(composeErrorNotification(`${result.error}`)));
    }
  };
};


export const startSetIncidencia2 = (body) => { 
  // viende de otro lado, no del detalle, no necesitamos bajar la asistencia
  return async (dispatch) => {
    const result = await post(
      `${URL_ASISTENCIA_INCIDENCIA}/SetIncidencia`,
      body
    );
    if (result.code === 0) {
      dispatch(startGetIncidenciasByAsistenciaID(body.asistenciaID))
      dispatch(notification(composeSuccessNotification(result.data)));
    } else {
      dispatch(notification(composeErrorNotification(`${result.error}`)));
    }
  };
};




//
export const startGetIncidenciasByAsistenciaID = (asistenciaID) => {
  return async (dispatch) => {
    const result = await get(
      `${URL_ASISTENCIA_INCIDENCIA}/GetIncidenciasByAsistenciaID/${asistenciaID}`
    );
    if (result.code === 0) {
      dispatch(storeIncidencias(result.data));
    } else {
      dispatch(notification(composeErrorNotification(`${result.error}`)));
    }
  };
}

const storeIncidencias = (incidencias) => ({
  type: types.storeIncidencias,
  payload: incidencias,
});