import React from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { ambiente, nameProject, ver } from "../constants/url";
import { startGetVer } from "../context/actions/notificationActions";

export const VerLogin = () => {
  const dispatch = useDispatch();
  const { versionBackend } = useSelector((state) => state.notificationReducer);
  useEffect(() => {
    dispatch(startGetVer());
  }, []);

  return (
    <div style={{ textAlign: 'center',  marginTop: 20, color: "#999" }}>

        {nameProject} {ver} {ambiente}
     {" "}
       {versionBackend}</div>
  
  );
};
