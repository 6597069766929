import React from "react";
import CardPage from "../../../layout/CardPage";
import { useGetServicios } from "./useGetServicios";
import { Button, Col, Row } from "antd";
import { ServicioItem } from "./servicioItem/ServicioItem";
import { useGetSemaforoUnaVez } from "./useGetSemaforoUnaVez";

export const SemaforoSeguimiento = () => {
  const { fetchData } = useGetSemaforoUnaVez();

  const { serviciosPorArribar } = useGetServicios();
  return (
    <CardPage titulo="Semáforo Seguimiento">
      <Row>
   
        <Col span={4}>
          <div className="subTitulo">{serviciosPorArribar.length} casos</div>
        </Col>
        <Col span={4}>
          <Button onClick={fetchData} size="small">Recargar datos</Button>
        </Col>
      </Row>

      <Row className="TituloTabla">
        <Col xs={8} xxl={2}>ID</Col>
        <Col xs={8} xxl={7}>Proveedor</Col>
        <Col xs={8} xxl={3}>Servicio</Col>
        <Col xs={8} xxl={2}>Asignación</Col>
        <Col xs={8} xxl={2}>Llega</Col>
        <Col xs={8} xxl={1}>L / C</Col>
        <Col xs={8} xxl={7}>Origen</Col>
      </Row>

      {serviciosPorArribar.map((s, i) => (
        <ServicioItem key={i} servicio={s} />
      ))}
    </CardPage>
  );
};
