import { useSelector } from "react-redux";
import { HistorialAsignacionItem } from "./HistorialAsignacionItem";
import { Skeleton } from "antd";

export const HistorialAsignacion = () => {
  const { historialAsignacion, loadingHistorialAsignacion } = useSelector((state) => state.detalleReducer);

  if(loadingHistorialAsignacion) return <Skeleton />
  return (
    <div>
      {historialAsignacion.map((h, i) => (
        <HistorialAsignacionItem key={i} historial={h} />
      ))}
    </div>
  );
};
